<div *ngIf="currentPage === TwoFactoAuthenticationPage.edit2fa">
    <div class="relative">
        <div *ngIf="loading" class="absolute top-0 left-0 w-full h-full bg-white-alpha-60 z-5">
            <div class="flex align-items-center w-full h-full">
                <p-progressSpinner class="m-auto" styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
    
        <span *ngIf="" class="text-xl font-semibold">{{'LABELS.enable-2fa' | translate}}</span>
        <span *ngIf="" class="text-xl font-semibold">{{'LABELS.change-2fa-method' | translate}}</span>
        <p-divider></p-divider>
        <div class="mt-5" pFocusTrap>
            <form [formGroup]="enable2FAForm" (keydown.enter)="handleEnterKey(enable2FAForm)">
                <div class="grid">
                    <div class="col-10 md:col-6 animated-element">
                        <i class="pi pi-envelope text-2xl"></i>
                        <span class="text-xl font-semibold ml-3">{{'LABELS.email' | translate}}</span>
                        <p class="mt-2">{{'LABELS.2fa-email-description' | translate}}</p>
                    </div>
                    <div class="col-2 md:col-6 flex align-content-center animated-element">
                        <p-radioButton inputId="2faType1" value="email" formControlName="type"></p-radioButton>
                    </div>
                    <div class="col-10 md:col-6 animated-element">
                        <i class="pi pi-calculator text-2xl"></i>
                        <span class="text-xl font-semibold ml-3">{{'LABELS.totp' | translate}}</span>
                        <p class="mt-2">{{'LABELS.2fa-totp-description' | translate}}</p>
                    </div>
                    <div class="col-2 md:col-6 flex align-content-center animated-element">
                        <p-radioButton inputId="2faType2" value="totp" formControlName="type"></p-radioButton>
                    </div>
                </div>
            </form>
            <div class="animated-element">
                <p-messages severity="warn" [enableService]="false" [closable]="false">
                    <ng-template pTemplate>
                        <div class="ml-2">{{'MESSAGES.2fa-type-warning' | translate}}</div>
                    </ng-template>
                </p-messages>
            </div>
            
            <div class="w-full">
                <p-messages></p-messages>
            </div>
        </div>
    </div>
    <div class="flex flex-row-reverse gap-2">
        <p-button label="{{'LABELS.save' | translate}}" [outlined]="true" (onClick)="enable2FA()" [loading]="loading" [disabled]="!enable2FAForm.valid"></p-button>
        <p-button label="{{'LABELS.back' | translate}}" [outlined]="true" severity="secondary" (onClick)="back()"></p-button>
    </div>
</div>

<div [@fadeInSlideUp] *ngIf="currentPage === TwoFactoAuthenticationPage.verify2faEmail">
    <div class="relative">
        <div *ngIf="loading" class="absolute top-0 left-0 w-full h-full bg-white-alpha-60 z-5">
            <div class="flex align-items-center w-full h-full">
                <p-progressSpinner class="m-auto" styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
    
        <span class="text-xl font-semibold">{{'LABELS.verify-2fa' | translate}}</span>
        <p-divider></p-divider>
        <div class="mt-5" pFocusTrap>
            <form [formGroup]="verify2FAEmailForm" (keydown.enter)="handleEnterKey(verify2FAEmailForm)">
                <div class="mb-5 animated-element">
                    <label for="2faEmailCode1" class="block text-900 font-medium mb-2">{{'LABELS.code' | translate}}</label>
                    <p-inputOtp
                        formControlName="code"
                        pAutoFocus
                        [autofocus]="true"
                        [length]="6"
                        (onChange)="verify2FAEmailForm.markAllAsTouched()"
                    >
                    </p-inputOtp>
                </div>
            </form>
            <div class="animated-element">
                <p-messages severity="info" [enableService]="false" [closable]="false">
                    <ng-template pTemplate>
                        <div class="ml-2">{{'MESSAGES.2fa-check-verification-email' | translate}}</div>
                    </ng-template>
                </p-messages>
            </div>
            
            <div class="w-full">
                <p-messages></p-messages>
            </div>
        </div>
    </div>
    <div class="flex flex-row-reverse gap-2">
        <p-button label="{{'LABELS.save' | translate}}" [outlined]="true" (onClick)="verify2FA()" [loading]="loading" [disabled]="!verify2FAEmailForm.valid"></p-button>
        <p-button label="{{'LABELS.back' | translate}}" [outlined]="true" severity="secondary" (onClick)="showEnable2FA()"></p-button>
    </div>
</div>

<div [@fadeInSlideUp] *ngIf="currentPage === TwoFactoAuthenticationPage.verify2faTotp">
    <div class="relative">
        <div *ngIf="loading" class="absolute top-0 left-0 w-full h-full bg-white-alpha-60 z-5">
            <div class="flex align-items-center w-full h-full">
                <p-progressSpinner class="m-auto" styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
    
        <span class="text-xl font-semibold">{{'LABELS.verify-2fa' | translate}}</span>
        <p-divider></p-divider>
        <div class="mt-5" pFocusTrap>
            <div class="flex flex-column md:flex-row w-full animated-element">
                <form [formGroup]="verify2FATOTPForm" (keydown.enter)="handleEnterKey(verify2FATOTPForm)">
                    <div class="flex flex-column mb-5">
                        <label for="2faTOTPCode1" class="block text-900 font-medium mb-2">{{'LABELS.code' | translate}}</label>
                        
                        <p-inputOtp
                            formControlName="code"
                            pAutoFocus
                            [autofocus]="true"
                            [integerOnly]="true"
                            [length]="6"
                            (onChange)="verify2FATOTPForm.markAllAsTouched()"
                        >
                        </p-inputOtp>
                        
                    </div>
                </form>
                <p-divider layout="vertical" class="hidden md:block"></p-divider>
                <div class="flex flex-column w-full align-items-center animated-element">
                    <div class="w-full max-w-22rem">
                        <p-image [src]="qrCodeTOTP" class="mx-auto" imageClass="w-full"></p-image>
                    </div>
                    <div>
                        <span>{{ totpSecret }}</span>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="animated-element">
            <p-messages severity="info" [enableService]="false" [closable]="false">
                <ng-template pTemplate>
                    <div class="ml-2">{{'MESSAGES.2fa-scan-qr' | translate}}</div>
                </ng-template>
            </p-messages>
        </div>
        
        <div class="w-full">
            <p-messages></p-messages>
        </div>
    </div>
    <div class="flex flex-row-reverse gap-2">
        <p-button label="{{'LABELS.save' | translate}}" [outlined]="true" (onClick)="verify2FA()" [loading]="loading" [disabled]="!verify2FATOTPForm.valid"></p-button>
        <p-button label="{{'LABELS.back' | translate}}" [outlined]="true" severity="secondary" (onClick)="showEnable2FA()"></p-button>
    </div>
</div>