import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { JwtService } from '../service/utils/jwt.service';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserProfileComponent } from '../pages/user-profile/user-profile.component';
import { UserProfileHeaderComponent } from '../pages/user-profile/user-profile-header.component';
import { ProfileService } from '../service/api/profile.service';
import { NGXLogger } from 'ngx-logger';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    providers: [DialogService, TranslateService],
    encapsulation: ViewEncapsulation.None,
})
export class AppTopBarComponent {
    loading: boolean = false;

    ref: DynamicDialogRef | undefined;

    public dynamicMenuItems$: BehaviorSubject<MenuItem[]> = new BehaviorSubject(
        [] as MenuItem[]
    );
    
    showMobileMenu() {
        this.dynamicMenuItems$.next(
            [
                {
                    label: this.translate.instant('LABELS.user'),
                    items: [
                        {
                            label: _.startCase(this.translate.instant('LABELS.profile')),
                            icon: 'pi pi-user',
                            command: () => {
                                this.showUserProfile();
                            }
                        },
                        
                        {
                            label: _.startCase(this.translate.instant('LABELS.logout')),
                            icon: 'pi pi-sign-out',
                            command: () => {
                                this.logout();
                            }
                        }
                    ]
                }
            ]
        )
    }

    langMenuItems: MenuItem[] = [
        {
            label: 'EN',
            command: () => {
                this.translate.use('en');
            }
        },
        
        {
            label: 'IT',
            command: () => {
                this.translate.use('it');
            }
        }
    ];

    currentLang = 'en';

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;
    
    constructor(
        public layoutService: LayoutService,
        private jwtService: JwtService,
        private router: Router,
        private dialogService: DialogService,
        private userProfileService: ProfileService,
        private logger: NGXLogger,
        public translate: TranslateService
    ) { 
        if (translate.currentLang == null) {
            translate.use(translate.defaultLang);
        }
    }

    async showUserProfile() {
        this.loading = true;
        try {
            const userProfileResponse = await lastValueFrom(this.userProfileService.profile());
            this.ref = this.dialogService.open(UserProfileComponent, {
                width: '60%',
                styleClass: '',
                contentStyle: { overflow: 'auto' },
                baseZIndex: 10000,
                maximizable: false,
                modal: true,
                focusOnShow: false,
                templates: {
                    header: UserProfileHeaderComponent
                },
                closable: true,
                data: userProfileResponse.data
            });   
        } catch (e) {
            this.logger.error('[AppTopBar] Error retrieving user profile' + JSON.stringify(e));
        }
        this.loading = false;
    }

    logout() {
        this.jwtService.removeAccessToken();
        this.jwtService.removeRefreshToken();
        this.router.navigate(['auth/login']);
    }
}
