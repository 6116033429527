import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RedirectCommand, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { tokenGuard } from './guards/token.guard';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { clearToastsGuard } from './guards/clear-toasts.guard';
import { userHomeGuard } from './guards/user-home.guard';
import { DownloadDocumentsComponent } from './pages/documents/download-documents/download-documents.component';
import { authRoleGuard } from './guards/auth-role.guard';
import { unsavedChangesGuard } from './guards/unsaved-changes.guard';
import { BusinessProcessesBPMNComponent } from './pages/business-processes/business-processes-bpmn/business-processes-bpmn.component';

const routes: Routes = [
  { path: 'organizations/:organizationId/documents/:documentId/:documentVersionId/download', component: DownloadDocumentsComponent, 
    canActivate: [clearToastsGuard, tokenGuard]
  },
  { 
    path: 'admin/organizations/dashboard/:organizationId/business-processes/:businessProcessId/edit/bpmn',
    component: BusinessProcessesBPMNComponent, 
    canActivate: [clearToastsGuard, tokenGuard],
    canDeactivate: [unsavedChangesGuard],
  },
  { 
    path: 'clusters/:clusterId/organizations/dashboard/:organizationId/business-processes/:businessProcessId/edit/bpmn',
    component: BusinessProcessesBPMNComponent, 
    canActivate: [clearToastsGuard, tokenGuard],
    canDeactivate: [unsavedChangesGuard],
  },
  { 
    path: 'organizations/:organizationId/business-processes/:businessProcessId/edit/bpmn',
    component: BusinessProcessesBPMNComponent, 
    canActivate: [clearToastsGuard, tokenGuard],
    canDeactivate: [unsavedChangesGuard]
  },
  {
    path: '', component: AppLayoutComponent,
    canMatch: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        console.log("Layout route");
        return true;
      }
    ],
    children: [
      { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [userHomeGuard] },
      { 
        path: 'admin',
        children: [
          { path: '', component: DashboardComponent},
          { 
            path: 'organizations/dashboard',
            loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule),
            canActivateChild: [
              (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
                if (!route.paramMap.has('organizationId')) {
                  const router = inject(Router);
                  const urlTree = router.parseUrl('/unauthorized');

                  return new RedirectCommand(urlTree);
                }
                
                return true;
              }
            ]
          },
          { path: 'organizations', loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule) },
          { path: 'users', loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
          { path: 'clusters', loadChildren: () => import('./pages/cluster/cluster.module').then(m => m.ClusterModule) },    
        ],
        canActivate: [authRoleGuard],
        data: { roles: ['alpha-admin'] }
      },
      // { path: 'users', loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
      { path: 'clusters', loadChildren: () => import('./pages/cluster/cluster.module').then(m => m.ClusterModule), canActivate: [authRoleGuard], data: { roles: ['alpha-admin', 'cluster-admin']} },    
      { path: 'organizations', loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule) },
      // { path: 'assets', loadChildren: () => import('./pages/assets/assets.module').then(m => m.AssetsModule) },
      { path: 'security-standards', loadChildren: () => import('./pages/security-standards/security-standards.module').then(m => m.SecurityStandardsModule) },

    ],
    canActivate: [tokenGuard]
  },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canActivate: [clearToastsGuard] },
  { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [clearToastsGuard] },
  { path: 'notfound', component: NotfoundComponent, canActivate: [clearToastsGuard] },
  { path: '**', redirectTo: '/notfound' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
