import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, Component, Input, output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { ProfileService } from 'src/app/service/api/profile.service';
import { AppEvent, AppEventType, EventQueueService } from 'src/app/service/utils/event-queue.service';
import { ToastService } from 'src/app/service/utils/toast.service';

@Component({
  selector: 'app-edit-user-details',
  templateUrl: './edit-user-details.component.html',
  styleUrl: './edit-user-details.component.scss',
  providers: [ ConfirmationService, MessageService, TranslateService]
})
export class EditUserDetailsComponent implements AfterContentInit{

  loading: boolean = false;
  @Input() user?: User | null = null;
  onSave = output<User | null>();
  onCancel = output<void>();
  editUserForm: FormGroup;


  constructor(
    private userProfileService: ProfileService,
    private toastService: ToastService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private translate: TranslateService,
    private eventQueueService: EventQueueService,
    private messageService: MessageService,
    private logger: NGXLogger
  ) {
    
    this.editUserForm = new FormGroup(
      {
        name: new FormControl<string | null>(null, [ Validators.required ]),
        lastName: new FormControl<string | null>(null, [ Validators.required ])
      }
    );
  }

  get name() {
    return this.editUserForm.get('name');
  }

  get lastName() {
    return this.editUserForm.get('lastName');
  }

  ngAfterContentInit(): void {
    if (this.user != null) {
      this.editUserForm.setValue({ name: this.user?.name, lastName: this.user?.last_name});
    }
  }

  handleEnterKey(editUserForm: FormGroup) {
    if (editUserForm.valid) {
      this.editUser()
    } else {
      this.editUserForm.markAllAsTouched();
      this.editUserForm.markAsDirty();
      this.editUserForm.markAsTouched();
    }
  }

  async editUser() {
    this.loading = true;
    try {
      const editUserResponse = await lastValueFrom(this.userProfileService.updateProfile(this.name?.value, this.lastName?.value));
      this.user = editUserResponse.data;
      this.toastService.showSuccess(this.translate.instant('MESSAGES.user-profile-updated'));
      this.eventQueueService.dispatch(new AppEvent<Message>(
        AppEventType.ProfileUpdatedEvent,
        this.user!
        ));
      this.onSave.emit(this.user);
    } catch (e) {
      this.messageService.clear();
      if (e instanceof HttpErrorResponse) {
        if (e.status === 401) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
        } else if (e.status === 422) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
        }
        this.logger.error('[EditUserProfile] Error editing user: ' + JSON.stringify(e));
      }
    }
    this.loading = false;
  }

  cancel() {
    this.resetForm();
    this.onCancel.emit();
  }

  resetForm() {
    this.editUserForm.reset();
    this.editUserForm.setValue({ name: this.user?.name, lastName: this.user?.last_name});
  }

}
