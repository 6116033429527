<div class="relative">
    <div *ngIf="loading" class="absolute top-0 left-0 w-full h-full bg-white-alpha-60 z-5">
        <div class="flex align-items-center w-full h-full">
            <p-progressSpinner class="m-auto" styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>

    <span class="text-xl font-semibold">{{'LABELS.totp-qr-code' | translate}}</span>
    <p-divider></p-divider>
    <div class="mt-5 animated-element" pFocusTrap>
        <div class="flex flex-column md:flex-row w-full">
            <div class="flex w-full">
                <p-image [src]="qrCodeTOTP" class="mx-auto" imageClass="w-full"></p-image>
            </div>
            
        </div>
    </div>
    <div class="animated-element">
        <p-messages severity="info" [enableService]="false" [closable]="false">
            <ng-template pTemplate>
                <div class="ml-2">{{'MESSAGES.2fa-scan-qr' | translate}}</div>
            </ng-template>
        </p-messages>
        <div class="w-full">
            <p-messages></p-messages>
        </div>
    </div>
</div>
<div class="flex flex-row-reverse gap-2">
    <p-button label="{{'LABELS.back' | translate}}" [outlined]="true" severity="secondary" (onClick)="back()"></p-button>
</div>