<div #topbar class="layout-topbar">
    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <a class="layout-topbar-logo" routerLink="">
        <span>ALPHA</span>
    </a>

    <div class="layout-topbar-menu-button">
        <p-menu #langmenumobile [model]="langMenuItems" [popup]="true" (window:scroll)="langmenumobile.hide()" appendTo="body"></p-menu>
        <button #topbarmenubutton class="p-link layout-topbar-button" (click)="langmenumobile.toggle($event)">
            {{ translate.currentLang | uppercase }}
        </button>
        <p-menu #mobileMenu [model]="(dynamicMenuItems$ | async)!" (onShow)="showMobileMenu()" [popup]="true" (window:scroll)="mobileMenu.hide()" appendTo="body"></p-menu>
        <button #topbarmenubutton class="p-link layout-topbar-button" (click)="mobileMenu.toggle($event)">
            <i class="pi pi-ellipsis-v"></i>
        </button>
    </div>
    
    <div #topbarmenu class="layout-topbar-menu">
        <p-menu #langmenu [model]="langMenuItems" [popup]="true" (window:scroll)="langmenu.hide()" appendTo="body"></p-menu>
        <button #topbarmenubutton class="p-link layout-topbar-button" (click)="langmenu.toggle($event)">
            {{ translate.currentLang | uppercase }}
        </button>
        <button class="p-link layout-topbar-button" (click)="showUserProfile()">
            <i class="pi pi-user"></i>
            <span>{{'LABELS.user' | translate}}</span>
        </button>
        <p-button label="{{'LABELS.logout' | translate}}" [text]="true" severity="danger" class="ml-2" (click)="logout()"></p-button>
    </div>
</div>