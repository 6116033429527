import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NGXLogger } from 'ngx-logger';
import { Message, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileRemoveEvent, FileSelectEvent } from 'primeng/fileupload';
import { lastValueFrom } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { ProfileService } from 'src/app/service/api/profile.service';
import { AppEvent, AppEventType, EventQueueService } from 'src/app/service/utils/event-queue.service';
import { ToastService } from 'src/app/service/utils/toast.service';

@Component({
  selector: 'app-edit-profile-picture',
  templateUrl: './edit-profile-picture.component.html',
  styleUrl: './edit-profile-picture.component.scss'
})
export class EditProfilePictureComponent {

  @Input() user?: User | null = null;
  onSave = output<User | null>();
  onCancel = output<void>();
  
  loading: boolean = false;
  editProfileImageFile?: File;
  croppedProfileImage?: Blob | null;
  ref: DynamicDialogRef | undefined;

  constructor(
    private userProfileService: ProfileService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private toastService: ToastService,
    private translate: TranslateService,
    private eventQueueService: EventQueueService,
    private messageService: MessageService,
    private logger: NGXLogger
  ) {
    this.user = this.dialogConfig.data;
  }

  onProfileImageFileChange(event: FileSelectEvent | FileRemoveEvent): void {
    if ('currentFiles' in event) {
      this.editProfileImageFile = event.currentFiles[0];
    } else {
      this.editProfileImageFile = undefined;
    }
  }

  cropImg(event: ImageCroppedEvent) {
    this.croppedProfileImage = event.blob;
  }


  async editProfilePicture() {
    this.loading = true;
    if (this.croppedProfileImage) {
      try {
        if (this.user != null) {
          const editUserResponse = await lastValueFrom(this.userProfileService.uploadProfileImage(this.croppedProfileImage));
          this.back();
          this.toastService.showSuccess(this.translate.instant('MESSAGES.user-profile-picture-updated'));
          this.eventQueueService.dispatch(new AppEvent<Message>(
            AppEventType.ProfileUpdatedEvent,
            this.user!
          ));
          this.onSave.emit(this.user)
        }
      } catch (e) {
        this.messageService.clear();
        if (e instanceof HttpErrorResponse) {
          if (e.status === 401) {
            this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
          } else if (e.status === 422) {
            this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
          }
          this.logger.error('[EditUserProfile] Error editing profile picture: ' + JSON.stringify(e));
        }
      }
    }
    this.loading = false;
  }

  back() {
    this.onCancel.emit();
  }
}
