import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, Component, output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { ProfileService } from 'src/app/service/api/profile.service';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrl: './qrcode.component.scss'
})
export class QRCodeComponent implements AfterContentInit {

  loading: boolean = false;
  qrCodeTOTP?: string;
  onCancel = output<void>();

  constructor(
    private dialogRef: DynamicDialogRef,
    private userProfileService: ProfileService,
    private messageService: MessageService,
    private logger: NGXLogger,
    private translate: TranslateService
  ) {}

  ngAfterContentInit(): void {
    this.getQRCode();
  }

  async getQRCode() {
    this.loading = true;
    if (this.qrCodeTOTP) {
      URL.revokeObjectURL(this.qrCodeTOTP);
      this.qrCodeTOTP = undefined;
    }
    try {
      const qrCodeResponse = await lastValueFrom(this.userProfileService.getQRCodeTOTP());
      this.qrCodeTOTP = URL.createObjectURL(qrCodeResponse);
    } catch (e) { 
      this.messageService.clear();
      if (e instanceof HttpErrorResponse) {
        if (e.status === 401) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
        } else if (e.status === 422) {
          this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
        }
        this.logger.error(`[${this.constructor.name}] Error getting qrcode image : ` + JSON.stringify(e));
      }
    }
    this.loading = false;
  }

  back() {
    this.onCancel.emit();
  }

}
