<ul class="layout-menu">
    <div *ngIf="showHeader" id="menu-header" class="pt-3 font-bold text-3xl text-primary">
        <div class="flex flex-row gap-3 align-items-center">
            <div id="organization-logo">
                <div *ngIf="menuService?.loading === true">
                    <div class=" flex align-items-center justify-content-center w-4rem h-4rem border-round">
                        <p-progressSpinner styleClass="w-1rem h-1rem alpha-spinner block" strokeWidth="8"
                            fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
                    </div>
                </div>
                <img *ngIf="organizationLogo != null" src="{{organizationLogo}}" class="h-4rem border-round">
            </div>
            <span *ngIf="organization != null">
                {{ organization.name }}
            </span>
            <span *ngIf="user?.role_name == 'cluster-admin' && organization == null">
                {{ user?.cluster?.name }}
            </span>
        </div>
        <p-divider></p-divider>
        <div *ngIf="showBackButton()" class="my-5">
            <p-button
                label="{{ 'LABELS.back-to-organizations'| translate }}"
                severity="primary" outlined="true"
                icon="pi pi-arrow-left"
                (click)="backToMainMenu()"
                styleClass="w-full">
            </p-button>
        </div>
    </div>
    
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
</ul>
