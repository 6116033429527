import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { AuthService } from 'src/app/service/api/auth.service';
import { ToastService } from 'src/app/service/utils/toast.service';
import { PasswordMatchValidator } from '../user-profile.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {

  @Input() user?: User | null = null;
  onSave = output<void>();
  onCancel = output<void>();
  
  loading: boolean = false;
  changePasswordForm: FormGroup;
  ref: DynamicDialogRef | undefined;

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private messageService: MessageService,
    private logger: NGXLogger,
    private translate: TranslateService
  ) {
    this.user = this.dialogConfig.data;
    this.changePasswordForm = new FormGroup(
      {
        oldPassword: new FormControl<string | null>(null, [Validators.required, Validators.minLength(8)]),
        newPassword: new FormControl<string | null>(null, [Validators.required, Validators.minLength(8)]),
        confirmPassword: new FormControl<string | null>(null, [Validators.required, Validators.minLength(8)])
      },{
        validators: [PasswordMatchValidator('newPassword', 'confirmPassword')]
      }
    );
  }

  get oldPassword() {
    return this.changePasswordForm.get('oldPassword');
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }

  handleEnterKey(changePasswordForm: FormGroup) {
    if (this.changePasswordForm.valid) {
      this.changePassword();
    } else {
      this.changePasswordForm.markAllAsTouched();
      this.changePasswordForm.markAsDirty();
      this.changePasswordForm.markAsTouched();
    }
  }

  async changePassword() {
    this.loading = true;
    try {
      if(this.user != null) {
        const passwordResponse = await lastValueFrom(this.authService.changePassword(this.oldPassword?.value, this.newPassword?.value));
        this.toastService.showSuccess(this.translate.instant('MESSAGES.password-successfully-changed'));
        this.onSave.emit();
      }
    } catch (e) {
      this.messageService.clear();
      if (e instanceof HttpErrorResponse) {
        if (e.status === 401) {
          if (e.error.message === 'Wrong password') {
            this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.wrong-password')});
          } else {
            this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
          }
        } else if (e.status === 422) {          
          if (e.error.message === 'Input validation error') {
            if(e.error.data.hasOwnProperty('new_password')) {
              if (Array.isArray(e.error.data.new_password)) {
                if (e.error.data.new_password.includes('The new password field must contain at least one uppercase and one lowercase letter.')) {
                  this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.password-lowercase-uppercase')});
                }
                if (e.error.data.new_password.includes('The new password field must contain at least one number.')) {
                  this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.password-number')});
                }
                if (e.error.data.new_password.includes('The new password field must contain at least one symbol.')) {
                  this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.password-symbol')});
                }
                if (e.error.data.new_password.includes('The given new password has appeared in a data leak. Please choose a different new password.')) {
                  this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.password-data-leak')});
                }
                if (e.error.data.new_password.includes('The new password field must be a string.')) {
                  this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.password-valid-string')});
                }
                if (e.error.data.new_password.includes('The new password field must be at least 8 characters.')) {
                  this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.password-eight-chars-length')});
                }
              }
            }
          } else {
            this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
          }
        }
        this.logger.error('[EditUserProfile] Error editing profile picture: ' + JSON.stringify(e));
      }
    }
    this.loading = false;
  }

  back() {
    this.changePasswordForm.reset();
    this.onCancel.emit();
  }
}
