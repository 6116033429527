<span class="p-dialog-title flex flex-row align-content-center" [id]="ariaLabelledBy + '_title'">
    <div *ngIf="profileImage" class ="mr-3">
        <p-avatar [style]="{'background-color':'var(--primary-color)', 'color': '#ffffff'}" size="xlarge" shape="circle" image="{{profileImage}}"></p-avatar>
    </div>
    <div *ngIf="!profileImage" class ="mr-3">
        <p-avatar [style]="{'background-color':'var(--primary-color)', 'color': '#ffffff'}" size="xlarge" shape="circle" label="{{userLabel}}"></p-avatar>
    </div>
    <span class="flex text-4xl font-normal align-items-center">{{ user?.name}} {{ user?.last_name }}</span>
</span>
<div class="p-dialog-header-icons">
    <button [ngClass]="'p-dialog-header-icon p-dialog-header-maximize p-link'" type="button" role="button" (click)="closeDialog()" (keydown.esc)="closeDialog()" [attr.aria-label]="closeAriaLabel">
        <TimesIcon [styleClass]="'p-dialog-header-close-icon'" />
    </button>
</div>



