<div class="layout-footer">
    <div class="flex flex-column align-items-center gap-2">
        <div>
            <span>Made with <i class="pi pi-heart-fill"></i>&nbsp;by V-Research</span>
        </div>
        <div class="version-number text-300">
            <span>Version {{ version }}</span>
        </div>   
    </div>
</div>
