import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, Component, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { User } from 'src/app/model/user.model';
import { AppEvent, AppEventType, EventQueueService } from 'src/app/service/utils/event-queue.service';
import { ProfileService } from 'src/app/service/api/profile.service';
import { lastValueFrom } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TimesIcon } from 'primeng/icons/times';
import { AvatarModule } from 'primeng/avatar';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-profile-header',
  templateUrl: './user-profile-header.component.html',
  styleUrl: './user-profile-header.component.css',
  standalone: true,
  imports: [ TimesIcon, AvatarModule, CommonModule, TranslateModule ]
})

export class UserProfileHeaderComponent implements OnDestroy, AfterContentInit {
  user?: User | null;
  userLabel?: string | null = null;
  closeAriaLabel?: string;
  ariaLabelledBy?: string;
  profileImage?: string;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private userProfileService: ProfileService,
    private eventQueueService: EventQueueService,
    private messageService: MessageService,
    private logger: NGXLogger,
    public translate: TranslateService
  ) {
    this.closeAriaLabel = dialogConfig.closeAriaLabel;
    this.ariaLabelledBy = dialogConfig.ariaLabelledBy;
    this.user = this.dialogConfig.data;    
    this.userLabel = this.dialogConfig.data.name.charAt(0).concat(this.dialogConfig.data.last_name.charAt(0));
    
    this.eventQueueService.on(AppEventType.ProfileUpdatedEvent).subscribe({
      next: (event: AppEvent<User | null>) => {
        this.user = event.payload;
        this.getProfilePicture();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.profileImage) {
      URL.revokeObjectURL(this.profileImage);
    }
  }

  ngAfterContentInit(): void {
    this.getProfilePicture();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async getProfilePicture() {
    if (this.profileImage) {
      URL.revokeObjectURL(this.profileImage);
      this.profileImage = undefined;
    }
    try {
      const userProfileResponse = await lastValueFrom(this.userProfileService.getProfileImage());
      this.profileImage = URL.createObjectURL(userProfileResponse);
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.messageService.clear();
        if (e instanceof HttpErrorResponse) {
          if (e.status === 401) {
            this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.unauthorized')});
          } else if (e.status === 422) {
            this.messageService.add({severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error')});
          }
          this.logger.error('[EditUserProfile] Error enabling two factor authentication: ' + JSON.stringify(e));
        }
      }
    }
  }
  

}
